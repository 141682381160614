import styled from 'styled-components';

const FooterWrapper = styled.section`
  position: relative;
  padding: 50px 0 30px;
  background-color: #202124;
  color: #949494;
  font-size: 14px;
    
    @media (max-width: 767px) {
      padding: 30px 0 20px;
    }
    
    @media (max-width: 575px) {
      padding: 20px 0 10px;
    }
    
    & a {
      color: #949494;
    }
    
    & a:hover, & a:focus {
      color: #fff;
    }
    
    & p {
      margin-top: 0;
    }
`;

export const FooterTop = styled.div`
  position: relative;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
    
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
`;

export const FooterMain = styled.div`
  position: relative;
  margin-bottom: 30px;
    
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
  
  & .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  
  & .col {
    width: 33.33%;
    padding-left: 15px;
    padding-right: 15px;
    
    @media (max-width: 767px) {
      width: 50%;
    }
    
    @media (max-width: 575px) {
      width: 100%;
    }
  }
  
  & .footer-title {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 25px;
    color: #fff;
    position: relative;
    padding-bottom: 16px;
    
    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 30px;
      height: 1px;
      background-color: #fff;
    }
  }
  
  & .footer-list {
    list-style: none;
    margin: 0 0 20px;
    
    & li {
      line-height: 1.3em;
      margin: 0 0 8px;
    }
  }
`;

export const FooterBottom = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px #515255;
  padding: 30px 0 1px;
    
    & p {
      margin-bottom: 0;
    }
`;

export default FooterWrapper;
