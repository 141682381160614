import React from "react";
import FooterWrapper, {FooterBottom, FooterMain, FooterTop,} from "./Footer.style";
import Container from "../../common/components/UI/Container";

import LogoImage from "../../images/vcs-logo-white.png";
import Logo from "../../elements/UI/Logo";
import Box from "../../elements/Box";
import Fade from "react-reveal/Fade";

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <Fade bottom>
          <FooterTop>
            <Logo
              href="/"
              logoSrc={LogoImage}
              title="VCS Soft Tech"
              className="footer-logo"
            />
          </FooterTop>

          <FooterMain>
            <Box className="row">
              <Box className="col">
                <h3 className="footer-title">Our Products</h3>
                <ul className="footer-list">
                  <li>
                    <a
                      href="https://themeforest.net/item/crema/26540158"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Crema
                    </a>
                  </li>
                  <li>
                    <a href="https://ant-cra.cremawork.com/" target="_blank" rel="noreferrer">
                      Crema Ant
                    </a>
                  </li>

                  <li>
                    <a href="https://hipster-mui.com/" target="_blank" rel="noreferrer">
                      Hipster
                    </a>
                  </li>
                </ul>
              </Box>

              <Box className="col">
                <h3 className="footer-title">Important Links</h3>
                <ul className="footer-list">
                  <li>
                    <a href="#feature_section">Features</a>
                  </li>
                  <li>
                    <a
                      href="https://themeforest.net/item/crema/26540158/support"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Support
                    </a>
                  </li>
                  <li>
                    <a href="#contact_us_section">Contact Us</a>
                  </li>
                </ul>
              </Box>

              <Box className="col">
                <h3 className="footer-title">Our Offices</h3>
                <p>Shop 17, Sangam market Jhunjhunu(Raj), India</p>
              </Box>
            </Box>
          </FooterMain>

          <FooterBottom>
            <p>© 2021 VCS Soft Technologies Pvt. Ltd.</p>
          </FooterBottom>
        </Fade>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
