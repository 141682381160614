import React, { useContext } from "react";
import PropTypes from "prop-types";
import NavbarWrapper from "../../elements/Navbar";
import Drawer from "../../elements/Drawer";
import Logo from "../../elements/UI/Logo";
import Box from "../../elements/Box";
import HamburgMenu from "../../common/components/HamburgMenu";
import Container from "../../common/components/UI/Container";
import { DrawerContext } from "../../common/contexts/DrawerContext";
import ScrollSpyMenu from "../../common/components/ScrollSpyMenu";

import LogoImage from "../../images/logo-dark.png";
import LogoWhiteImage from "../../images/logo-white.png";

import Fade from "react-reveal/Fade";
// import {graphql, useStaticQuery} from "gatsby";

const Navbar = ({ navbarStyle, logoStyle, row, menuItems, menuWrapper }) => {

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE",
    });
  };

  return (
    <NavbarWrapper {...navbarStyle} className="saas_navbar">
      <Container>
        <Fade top>
        <Box {...row}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Logo
              href="/"
              logoSrc={LogoImage}
              title="Crema Logo"
              logoStyle={logoStyle}
              className="main-logo"
            />
            <Logo
              href="/"
              logoSrc={LogoWhiteImage}
              title="Crema Logo"
              logoStyle={logoStyle}
              className="main-logo-white"
            />
          </div>
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={menuItems}
              offset={-70}
            />
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#000" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={menuItems}
                drawerClose={true}
                offset={-100}
              />
            </Drawer>
          </Box>
        </Box>
        </Fade>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
  menuItems: PropTypes.array,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: "70px",
    display: "block",
  },
  row: {
    flexBox: true,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  logoStyle: {
    maxWidth: ["50px", "55px"],
  },
  button: {
    type: "button",
    fontSize: "13px",
    fontWeight: "700",
    borderRadius: "4px",
    pl: "15px",
    pr: "15px",
    colors: "secondaryWithBg",
    minHeight: "auto",
    height: "40px",
  },
  menuWrapper: {
    flexBox: true,
    alignItems: "center",
  },
};

export default Navbar;
