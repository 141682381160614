import styled, { css } from "styled-components";

const ContainerWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
      max-width: none !important;
    `};
  ${(props) =>
    (props.noGutter &&
      css`
        padding-left: 0;
        padding-right: 0;
      `) ||
    css`
      padding-left: 15px;
      padding-right: 15px;
    `};
  @media (min-width: 768px) {
    max-width: 750px;
    width: 100%;
  }
  @media (min-width: 992px) {
    max-width: 970px;
    width: 100%;
  }
  @media (min-width: 1280px) {
    max-width: 1170px;
    width: 100%;
  }
  @media (min-width: 1560px) {
    max-width: 1400px;
    width: 100%;
  }
  @media (min-width: 1920px) {
    max-width: ${(props) => props.width || "1400"}px;
    width: 100%;
  }
  .blockTitle {
    text-align: center;
    margin-bottom: 50px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 767px) {
      margin-bottom: 33px;
    }
    h2 {
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 1;
      letter-spacing: -0.03em;
      color: #09131f;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.4;
      color: #858b91;
      margin: 0;
      margin-top: 20px;
    }
  }
`;

export default ContainerWrapper;
